<template>
  <div id="mian">
    <div class="header" v-if="infoType != 'MANUAL'">
      <span id="fzcolor">店铺详情</span>
      <span @click="toTypeUrl('shoplist_xq_shop')">报备商户号信息</span>
    </div>
    <div v-auth="'ACCOUNT:STORE:STORES:BASE'">
      <div class="desc" :style="{ marginTop: infoType == 'MANUAL' ? '5px' : '' }">
        <h5 class="jsxx float_left">基本信息</h5>
        <div>
          <el-button
            v-auth="'ACCOUNT:STORE:STORES:BASE/EDIT'"
            @click="toTypeUrl('add_shoplist_edit')"
            class="editBt"
            type="primary"
            v-if="infoType != 'MANUAL'"
            >编辑</el-button
          >
          <el-button
            v-auth="'ACCOUNT:STORE:STORES:BASE/EDIT'"
            @click="modifyManual"
            class="editBt"
            type="primary"
            v-if="infoType == 'MANUAL'"
            >编辑</el-button
          >
        </div>
      </div>
      <div class="list clear"
        v-if="
          infoType != 'MANUAL' &&
          (detail.merchantType == 'ENTERPRISE' ||
            detail.merchantType == 'INDIVIDUAL_BUSINESS')
        "
      >
        <ul class="listBox">
          <li>
            <span>店铺名称</span>
            <i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detail.storeName"
                placement="top"
              >
                <span>{{ detail.storeName }}</span>
              </el-tooltip>
            </i>
          </li>
          <li>
            <span>所属商户</span>
            <i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detail.mchName"
                placement="top"
              >
                <span>{{ detail.mchName }}</span>
              </el-tooltip>
            </i>
          </li>
          <li>
            <span>店铺编号</span>
            <i class="hidden1">{{ detail.storeId }}</i>
          </li>
          <li>
            <span>店铺简称</span>
            <i class="hidden1">{{ detail.storeShortName }}</i>
          </li>
          <li>
            <span>店铺内部ID</span>
            <i class="hidden1">{{ detail.internalId }}</i>
          </li>
          <li>
            <span>客服电话</span>
            <i class="hidden1">{{ detail.servicePhone }}</i>
          </li>
          <li>
            <span>经营类目</span>
            <i class="hidden1">{{ detail.category }}</i>
          </li>
          <li>
            <span>店铺经营地址</span>
            <i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detail.storeAddress"
                placement="top"
              >
                <span>{{ detail.storeAddress }}</span>
              </el-tooltip>
            </i>
          </li>
          <li>
            <span>地区</span>
            <i class="hidden1">
              {{ detail.province }}{{ detail.city ? "-" + detail.city : ""
              }}{{ detail.county ? "-" + detail.county : "" }}
            </i>
          </li>
          <li>
            <span>店铺主体类型</span>
            <i class="hidden1">{{ merchantType[detail.merchantType] }}</i>
          </li>
          <li>
            <span>店铺证件类型</span>
            <i class="hidden1">{{ licenceType[detail.licenceType] }}</i>
          </li>
          <li>
            <span>证件编号</span>
            <i class="hidden1">{{ detail.licenceNo }}</i>
          </li>
          <li>
            <span>注册地址</span>
            <i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detail.companyAddress"
                placement="top"
              >
                <span>{{ detail.companyAddress }}</span>
              </el-tooltip>
            </i>
          </li>
          <li>
            <span>营业执照有效期</span>
            <i class="hidden1" v-if="!detail.licenceStartDate && !detail.licenceEndDate"></i>
            <i v-else class="hidden1">
              {{ detail.licenceStartDate
              }}<span v-if="detail.licenceStartDate && detail.licenceEndDate"
                >至</span
              >{{
                detail.licenceEndDate == "forever" 
                  ? "长期"
                  : detail.licenceEndDate
              }}
            </i>
          </li>
          <li>
            <span>营业执照</span>
            <i class="hidden1">
              <a
                v-if="detail.licencePicture"
                href="javascript:;"
                @click="showImg(detail.licencePicture)"
                >点击预览</a
              >
            </i>
          </li>
          <li>
            <span>法人姓名</span>
            <i class="hidden1">{{ detail.legalPersonName }}</i>
          </li>
          <li>
            <span>法人证件类型</span>
            <i class="hidden1">
              {{ legalPersonCemType[detail.legalPersonCemType] }}
            </i>
          </li>
          <li>
            <span>法人证件有效期</span>
            <i class="hidden1"
              v-if="
                !detail.legalPersonCemStartDate && !detail.legalPersonCemEndDate
              "
            ></i>
            <i v-else class="hidden1">
              {{ detail.legalPersonCemStartDate
              }}<span
                v-if="
                  detail.legalPersonCemStartDate && detail.legalPersonCemEndDate
                "
                >至</span
              >{{
                detail.legalPersonCemEndDate == "forever"
                  ? "长期"
                  : detail.legalPersonCemEndDate
              }}
            </i>
          </li>
          <li>
            <span>法人证件号码</span>
            <i class="hidden1">{{ detail.legalPersonCemNo }}</i>
          </li>
          <li>
            <span>法人证件正面照片</span>
            <i class="hidden1">
              <a
                v-if="detail.idcardNormalPicture"
                href="javascript:;"
                @click="showImg(detail.idcardNormalPicture)"
                >点击预览</a
              >
            </i>
          </li>          
          <li>
            <span>法人证件反面照片</span>
            <i class="hidden1">
              <a
                v-if="detail.idcardReversePicture"
                href="javacript:;"
                @click="showImg(detail.idcardReversePicture)"
                >点击预览</a
              >
            </i>
          </li>
          <li>
            <span>法人证件照地址</span>
            <i class="hidden1">{{ detail.idAddress }}</i>
          </li>
          <li>
            <span>法人为受益人</span>
            <i class="hidden1" v-show="detail.legalPersonIsBene==1">是</i>
            <i class="hidden1" v-show="detail.legalPersonIsBene==2">否</i>
          </li>
          <li>
            <span>法人为联系人</span>
            <i class="hidden1" v-show="detail.legalPersonIsContact==1">是</i>
            <i class="hidden1" v-show="detail.legalPersonIsContact==2">否</i>
          </li>
          <li>
            <span>联系人</span>
            <i class="hidden1">{{ detail.contactName }}</i>
          </li>
          <li>
            <span>联系人手机</span>
            <i class="hidden1">{{ detail.contactPhone }}</i>
          </li>
          <li>
            <span>联系人邮箱</span>
            <i class="hidden1">{{ detail.contactEmail }}</i>
          </li>
          <li>
            <span>联系人证件类型</span>
            <i class="hidden1">
              {{ legalPersonCemType[detail.contactCemType] }}
            </i>
          </li>
          <li>
            <span>联系人证件号码</span>
            <i class="hidden1">{{ detail.contactIdCard }}</i>
          </li>
          <li>
            <span>联系人证件有效期</span>
            <i class="hidden1" v-if="!detail.contactValidStartDate && !detail.contactValidStartDate"></i>
            <i v-else class="hidden1">
              {{ detail.contactValidStartDate
              }}<span v-if="detail.contactValidStartDate && detail.contactValidStartDate"
                >至</span
              >{{
                detail.contactValidSEndDate == "forever"
                  ? "长期"
                  : detail.contactValidSEndDate
              }}
            </i>
          </li>
          <li>
            <span>联系人证件正面照片</span>
            <i class="hidden1" v-show="detail.contactIdNomalPic != ''">
              <a
                v-if="detail.contactIdNomalPic"
                href="javacript:;"
                @click="showImg(detail.contactIdNomalPic)"
                >点击预览</a
              >
            </i>
          </li>
          <li>
            <span>联系人证件反面照片</span>
            <i class="hidden1" v-show="detail.contactIdReversePic != ''">
              <a
                v-if="detail.contactIdReversePic"
                href="javacript:;"
                @click="showImg(detail.contactIdReversePic)"
                >点击预览</a
              >
            </i>
          </li>
          <li>
            <span>业务办理授权函</span>
            <i class="hidden1" v-show="detail.authorizationLetter != ''">
              <a
                v-if="detail.authorizationLetter"
                href="javacript:;"
                @click="showImg(detail.authorizationLetter)"
                >点击预览</a
              >
            </i>
          </li>
          <li>
            <span>店铺支付宝账户</span>
            <i class="hidden1">
              {{ detail.alipayId ? detail.alipayId : "" }}
            </i>
          </li>
          <li>
            <span>联系人微信号</span>
            <i class="hidden1">
              {{ detail.contactWechat ? detail.contactWechat : "" }}
            </i>
          </li>
          <li>
            <span>创建时间</span>
            <i class="hidden1">{{ detail.createTime }}</i>
          </li>
          <li>
            <span>审核时间</span>
            <i class="hidden1">
              {{ detail.checkTime ? detail.checkTime : "" }}
            </i>
          </li>
          <li>
            <span>最后修改时间</span>
            <i class="hidden1">{{ detail.lastUpdateTime }}</i>
          </li>
          <li>
            <span>最后修改人</span>
            <i class="hidden1">{{ detail.lastUpdateUser }}</i>
          </li>
        </ul>
      </div>
      <div class="list clear"
        v-if="
          infoType != 'MANUAL' &&
          detail.merchantType != 'ENTERPRISE' &&
          detail.merchantType != 'INDIVIDUAL_BUSINESS' &&
          detail.merchantType != 'MICRO_ENTERPRISE'
        "
      >
        <ul class="listBox">
          <li>
            <span>店铺名称</span>
            <i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detail.storeName"
                placement="top"
              >
                <span>{{ detail.storeName }}</span>
              </el-tooltip>
            </i>
          </li>
          <li>
            <span>所属商户</span>
            <i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detail.mchName"
                placement="top"
              >
                <span>{{ detail.mchName }}</span>
              </el-tooltip>
            </i>
          </li>
          <li>
            <span>店铺编号</span>
            <i class="hidden1">{{ detail.storeId }}</i>
          </li>
          <li>
            <span>店铺简称</span>
            <i class="hidden1">{{ detail.storeShortName }}</i>
          </li>
          <li>
            <span>店铺内部ID</span>
            <i class="hidden1">{{ detail.internalId }}</i>
          </li>
          <li>
            <span>客服电话</span>
            <i class="hidden1">{{ detail.servicePhone }}</i>
          </li>
          <li>
            <span>经营类目</span>
            <i class="hidden1">{{ detail.category }}</i>
          </li>
          <li>
            <span>店铺经营地址</span>
            <i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detail.storeAddress"
                placement="top"
              >
                <span>{{ detail.storeAddress }}</span>
              </el-tooltip>
            </i>
          </li>
          <li>
            <span>地区</span>
            <i class="hidden1">
              {{ detail.province }}{{ detail.city ? "-" + detail.city : ""
              }}{{ detail.county ? "-" + detail.county : "" }}
            </i>
          </li>
          <li>
            <span>店铺主体类型</span>
            <i class="hidden1">{{ merchantType[detail.merchantType] }}</i>
          </li>
          <li>
            <span>店铺证件类型</span>
            <i class="hidden1">{{ licenceType[detail.licenceType] }}</i>
          </li>
          <li>
            <span>证件编号</span>
            <i class="hidden1">{{ detail.licenceNo }}</i>
          </li>
          <li>
            <span>注册地址</span>
            <i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detail.companyAddress"
                placement="top"
              >
                <span>{{ detail.companyAddress }}</span>
              </el-tooltip>
            </i>
          </li>
          <li>
            <span>登记证件有效期</span>
            <i class="hidden1" v-if="!detail.licenceStartDate && !detail.licenceEndDate"></i>
            <i v-else class="hidden1">
              {{ detail.licenceStartDate
              }}<span v-if="detail.licenceStartDate && detail.licenceEndDate"
                >至</span
              >{{
                detail.licenceEndDate == "forever"
                  ? "长期"
                  : detail.licenceEndDate
              }}
            </i>
          </li>
          <li>
            <span>证件照片</span>
            <i class="hidden1">
              <a
                v-if="detail.licencePicture"
                href="javascript:;"
                @click="showImg(detail.licencePicture)"
                >点击预览</a
              >
            </i>
          </li>
          <li>
            <span>单位证明函</span>
            <i class="hidden1">
              <a
                v-if="detail.licenceCompanyPicture"
                href="javascript:;"
                @click="showImg(detail.licenceCompanyPicture)"
                >点击预览</a
              >
            </i>
          </li>
          <li>
            <span>法人姓名</span>
            <i class="hidden1">{{ detail.legalPersonName }}</i>
          </li>
          <li>
            <span>法人证件类型</span>
            <i class="hidden1">
              {{ legalPersonCemType[detail.legalPersonCemType] }}
            </i>
          </li>
          <li>
            <span>法人证件有效期</span>
            <i class="hidden1"
              v-if="
                !detail.legalPersonCemStartDate && !detail.legalPersonCemEndDate
              "
            ></i>
            <i v-else class="hidden1">
              {{ detail.legalPersonCemStartDate
              }}<span
                v-if="
                  detail.legalPersonCemStartDate && detail.legalPersonCemEndDate
                "
                >至</span
              >{{
                detail.legalPersonCemEndDate == "forever"
                  ? "长期"
                  : detail.legalPersonCemEndDate
              }}
            </i>
          </li>
          <li>
            <span>法人证件号码</span>
            <i class="hidden1">{{ detail.legalPersonCemNo }}</i>
          </li>
          <li>
            <span>法人证件正面照</span>
            <i class="hidden1">
              <a
                v-if="detail.idcardNormalPicture"
                href="javacript:;"
                @click="showImg(detail.idcardNormalPicture)"
                >点击预览</a
              >
            </i>
          </li>
          <li>
            <span>法人证件反面照片</span>
            <i class="hidden1">
              <a
                v-if="detail.idcardReversePicture"
                href="javascript:;"
                @click="showImg(detail.idcardReversePicture)"
                >点击预览</a
              >
            </i>
          </li>
          <li>
            <span>法人证件照地址</span>
            <i class="hidden1">{{ detail.idAddress }}</i>
          </li>
          <li>
            <span>法人为受益人</span>
            <i class="hidden1" v-show="detail.legalPersonIsBene==1">是</i>
            <i class="hidden1" v-show="detail.legalPersonIsBene==2">否</i>
          </li>
          <li>
            <span>法人为联系人</span>
            <i class="hidden1" v-show="detail.legalPersonIsContact==1">是</i>
            <i class="hidden1" v-show="detail.legalPersonIsContact==2">否</i>
          </li> 
          <li>
            <span>联系人</span>
            <i class="hidden1">{{ detail.contactName }}</i>
          </li> 
          <li>
            <span>联系人手机</span>
            <i class="hidden1">{{ detail.contactPhone }}</i>
          </li>
          <li>
            <span>联系人邮箱</span>
            <i class="hidden1">{{ detail.contactEmail }}</i>
          </li>
          <li>
            <span>联系人证件类型</span>
            <i class="hidden1">
              {{ legalPersonCemType[detail.contactCemType] }}
            </i>
          </li>
          <li>
            <span>联系人证件号码</span>
            <i class="hidden1">{{ detail.contactIdCard }}</i>
          </li>
          <li>
            <span>联系人证件有效期</span>
            <i class="hidden1" v-if="!detail.contactValidStartDate && !detail.contactValidStartDate"></i>
            <i v-else class="hidden1">
              {{ detail.contactValidStartDate
              }}<span v-if="detail.contactValidStartDate && detail.contactValidStartDate"
                >至</span
              >{{
                detail.contactValidSEndDate == "forever"
                  ? "长期"
                  : detail.contactValidSEndDate
              }}
            </i>
          </li>
          <li>
            <span>联系人证件正面照片</span>
            <i class="hidden1" v-show="detail.contactIdNomalPic != ''">
              <a
                v-if="detail.contactIdNomalPic"
                href="javacript:;"
                @click="showImg(detail.contactIdNomalPic)"
                >点击预览</a
              >
            </i>
          </li>
          <li>
            <span>联系人证件反面照片</span>
            <i class="hidden1" v-show="detail.contactIdReversePic != ''">
              <a
                v-if="detail.contactIdReversePic"
                href="javacript:;"
                @click="showImg(detail.contactIdReversePic)"
                >点击预览</a
              >
            </i>
          </li>
          <li>
            <span>业务办理授权函</span>
            <i class="hidden1" v-show="detail.authorizationLetter != ''">
              <a
                v-if="detail.authorizationLetter"
                href="javacript:;"
                @click="showImg(detail.authorizationLetter)"
                >点击预览</a
              >
            </i>
          </li>
          <li>
            <span>店铺支付宝账号</span>
            <i class="hidden1">
              {{ detail.alipayId ? detail.alipayId : "" }}
            </i>
          </li>
          <li>
            <span>联系人微信号</span>
            <i class="hidden1">
              {{ detail.contactWechat ? detail.contactWechat : "" }}
            </i>
          </li>
          <li>
            <span>创建时间</span>
            <i class="hidden1">{{ detail.createTime }}</i>
          </li>
          <li>
            <span>审核时间</span>
            <i class="hidden1">
              {{ detail.checkTime ? detail.checkTime : "" }}
            </i>
          </li>
          <li>
            <span>最后修改时间</span>
            <i class="hidden1">{{ detail.lastUpdateTime }}</i>
          </li>
          <li>
            <span>最后修改人</span>
            <i class="hidden1">{{ detail.lastUpdateUser }}</i>
          </li>
        </ul>
      </div>
      <div class="list clear"
        v-if="infoType != 'MANUAL' && detail.merchantType == 'MICRO_ENTERPRISE'"
      >
        <ul class="listBox">
          <li>
            <span>店铺名称</span>
            <i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detail.storeName"
                placement="top"
              >
                <span>{{ detail.storeName }}</span>
              </el-tooltip>
            </i>
          </li>
          <li>
            <span>所属商户</span>
            <i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detail.mchName"
                placement="top"
              >
                <span>{{ detail.mchName }}</span>
              </el-tooltip>
            </i>
          </li>
          <li>
            <span>店铺编号</span>
            <i class="hidden1">{{ detail.storeId }}</i>
          </li>
          <li>
            <span>店铺简称</span>
            <i class="hidden1">{{ detail.storeShortName }}</i>
          </li>
          <li>
            <span>店铺内部ID</span>
            <i class="hidden1">{{ detail.internalId }}</i>
          </li>
          <li>
            <span>客服电话</span>
            <i class="hidden1">{{ detail.servicePhone }}</i>
          </li>
          <li>
            <span>经营类目</span>
            <i class="hidden1">{{ detail.category }}</i>
          </li>
          <li>
            <span>店铺经营地址</span>
            <i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detail.storeAddress"
                placement="top"
              >
                <span>{{ detail.storeAddress }}</span>
              </el-tooltip>
            </i>
          </li>
          <li>
            <span>地区</span>
            <i class="hidden1">
              {{ detail.province }}{{ detail.city ? "-" + detail.city : ""
              }}{{ detail.county ? "-" + detail.county : "" }}
            </i>
          </li>
          <li>
            <span>店铺主体类型</span>
            <i class="hidden1">{{ merchantType[detail.merchantType] }}</i>
          </li>
          <li>
            <span>小微经营类型</span>
            <i class="hidden1">{{ microType[detail.microType] }}</i>
          </li>
          <li>
            <span>店铺名称</span>
            <i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detail.shopName"
                placement="top"
              >
                <span>{{ detail.shopName }}</span>
              </el-tooltip>
            </i>
          </li>
          <li>
            <span>店铺地址</span>
            <i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detail.shopAddress"
                placement="top"
              >
                <span>{{ detail.shopAddress }}</span>
              </el-tooltip>
            </i>
          </li>
          <li>
            <span>店铺门头照片</span>
            <i class="hidden1">
              <a
                v-if="detail.shopEntrancePicture"
                href="javascript:;"
                @click="showImg(detail.shopEntrancePicture)"
                >点击预览</a
              >
            </i>
          </li>
          <li>
            <span>店内环境照片</span>
            <i class="hidden1">
              <a
                v-if="detail.shopIndoorPicture"
                href="javascript:;"
                @click="showImg(detail.shopIndoorPicture)"
                >点击预览</a
              >
            </i>
          </li>
          <li>
            <span>法人姓名</span>
            <i class="hidden1">{{ detail.legalPersonName }}</i>
          </li>
          <li>
            <span>法人证件类型</span>
            <i class="hidden1">
              {{ legalPersonCemType[detail.legalPersonCemType] }}
            </i>
          </li>
          <li>
            <span>法人证件有效期</span>
            <i class="hidden1"
              v-if="
                !detail.legalPersonCemStartDate && !detail.legalPersonCemEndDate
              "
            ></i>
            <i v-else class="hidden1">
              {{ detail.legalPersonCemStartDate
              }}<span
                v-if="
                  detail.legalPersonCemStartDate && detail.legalPersonCemEndDate
                "
                >至</span
              >{{
                detail.legalPersonCemEndDate == "forever"
                  ? "长期"
                  : detail.legalPersonCemEndDate
              }}
            </i>
          </li>
          <li>
            <span>法人证件号码</span>
            <i class="hidden1">{{ detail.legalPersonCemNo }}</i>
          </li>
          <li>
            <span>法人证件正面照片</span>
            <i class="hidden1">
              <a
                v-if="detail.idcardNormalPicture"
                href="javascript:;"
                @click="showImg(detail.idcardNormalPicture)"
                >点击预览</a
              >
            </i>
          </li>
          <li>
            <span>法人证件反面照</span>
            <i class="hidden1">
              <a
                v-if="detail.idcardReversePicture"
                href="javacript:;"
                @click="showImg(detail.idcardReversePicture)"
                >点击预览</a
              >
            </i>
          </li>
          <li>
            <span>法人证件照地址</span>
            <i class="hidden1">{{ detail.idAddress }}</i>
          </li>
          <li>
            <span>法人为受益人</span>
            <i class="hidden1" v-show="detail.legalPersonIsBene==1">是</i>
            <i class="hidden1" v-show="detail.legalPersonIsBene==2">否</i>
          </li>
          <li>
            <span>法人为联系人</span>
            <i class="hidden1" v-show="detail.legalPersonIsContact==1">是</i>
            <i class="hidden1" v-show="detail.legalPersonIsContact==2">否</i>
          </li> 
          <li>
            <span>联系人</span>
            <i class="hidden1">{{ detail.contactName }}</i>
          </li>
          <li>
            <span>联系人手机</span>
            <i class="hidden1">{{ detail.contactPhone }}</i>
          </li>
          <li>
            <span>联系人邮箱</span>
            <i class="hidden1">{{ detail.contactEmail }}</i>
          </li>
          <li>
            <span>联系人证件类型</span>
            <i class="hidden1">
              {{ legalPersonCemType[detail.contactCemType] }}
            </i>
          </li>
          <li>
            <span>联系人证件号码</span>
            <i class="hidden1">{{ detail.contactIdCard }}</i>
          </li>
          <li>
            <span>联系人证件有效期</span>
            <i class="hidden1" v-if="!detail.contactValidStartDate && !detail.contactValidStartDate"></i>
            <i v-else class="hidden1">
              {{ detail.contactValidStartDate
              }}<span v-if="detail.contactValidStartDate && detail.contactValidStartDate"
                >至</span
              >{{
                detail.contactValidSEndDate == "forever"
                  ? "长期"
                  : detail.contactValidSEndDate
              }}
            </i>
          </li>
          <li>
            <span>联系人证件正面照片</span>
            <i class="hidden1" v-show="detail.contactIdNomalPic != ''">
              <a
                v-if="detail.contactIdNomalPic"
                href="javacript:;"
                @click="showImg(detail.contactIdNomalPic)"
                >点击预览</a
              >
            </i>
          </li>
          <li>
            <span>联系人证件反面照片</span>
            <i class="hidden1" v-show="detail.contactIdReversePic != ''">
              <a
                v-if="detail.contactIdReversePic"
                href="javacript:;"
                @click="showImg(detail.contactIdReversePic)"
                >点击预览</a
              >
            </i>
          </li>
          <li>
            <span>业务办理授权函</span>
            <i class="hidden1" v-show="detail.authorizationLetter != ''">
              <a
                v-if="detail.authorizationLetter"
                href="javacript:;"
                @click="showImg(detail.authorizationLetter)"
                >点击预览</a
              >
            </i>
          </li>
          <li>
            <span>店铺支付宝账户</span>
            <i class="hidden1">
              {{ detail.alipayId ? detail.alipayId : "" }}
            </i>
          </li>
          <li>
            <span>联系人微信号</span>
            <i class="hidden1">
              {{ detail.contactWechat ? detail.contactWechat : "" }}
            </i>
          </li>
          <li>
            <span>创建时间</span>
            <i class="hidden1">{{ detail.createTime }}</i>
          </li>
          <li>
            <span>审核时间</span>
            <i class="hidden1">
              {{ detail.checkTime ? detail.checkTime : "" }}
            </i>
          </li>
          <li>
            <span>最后修改时间</span>
            <i class="hidden1">{{ detail.lastUpdateTime }}</i>
          </li>
          <li>
            <span>最后修改人</span>
            <i class="hidden1">{{ detail.lastUpdateUser }}</i>
          </li>
        </ul>
      </div>
      <div class="list clear" v-if="infoType == 'MANUAL'">
        <ul class="listBox">
          <li>
            <span>店铺编号</span>
            <i class="hidden1">{{ detail.storeId }}</i>
          </li>
          <li>
            <span>店铺名称</span>
            <i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detail.storeName"
                placement="top"
              >
                <span>{{ detail.storeName }}</span>
              </el-tooltip>
            </i>
          </li>
          <li>
            <span>商户编号</span>
            <i class="hidden1">{{ detail.mchId }}</i>
          </li>
          <li>
            <span>所属商户</span>
            <i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detail.mchName"
                placement="top"
              >
                <span>{{ detail.mchName }}</span>
              </el-tooltip>
            </i>
          </li>
          <li>
            <span>商户平台显示</span>
            <i class="hidden1">{{ detail.showInMch ? "是" : "否" }}</i>
          </li>
        </ul>
      </div>
      <div class="desc" v-if="infoType != 'MANUAL'">
        <h5 class="jsxx">结算信息</h5>
      </div>
      <div class="tab1" v-if="infoType != 'MANUAL'">
        <table class="tab">
          <tr class="tab_title">
            <td style="width: 138px">
              <p>账户名</p>
            </td>
            <td style="width: 168px">
              <p>账号</p>
            </td>
            <td style="width: 60px">
              <p>联行号</p>
            </td>
            <td style="width: 70px">
              <p>账户类型</p>
            </td>
            <td>
              <p>操作</p>
            </td>
          </tr>
          <tr>
            <td style="margin-left: 15px">
              <p>{{ accountTab.accountName }}</p>
            </td>
            <td>
              <p>{{ accountTab.accountNo }}</p>
            </td>
            <td style="width: 85px">
              <p>{{ accountTab.interBranchesNo }}</p>
            </td>
            <td>
              <p>
                {{
                  accountTab.accountType == "COMPANY"
                    ? "企业"
                    : accountTab.accountType == "PERSONAL"
                    ? "个人"
                    : ""
                }}
              </p>
            </td>
            <td style="width: 66px">
              <p v-auth="'ACCOUNT:STORE:STORES:BASE/EDIT'">
                <a href="javascript:;" @click="openEditInfo" class="lianjie"
                  >编辑</a
                >
              </p>
            </td>
          </tr>
        </table>
      </div>
      <div class="desc" v-if="infoType != 'MANUAL'">
        <h5 class="jsxx">配置信息</h5>
      </div>
      <div class="tab1" v-if="infoType != 'MANUAL'">
        <table class="tab">
          <tr class="tab_title">
            <td style="width: 138px">
              <p>支付类型</p>
            </td>
            <td style="width: 168px">
              <p>使用场景</p>
            </td>
            <td>
              <p>操作</p>
            </td>
          </tr>
          <tr>
            <td style="margin-left: 15px">
              <p>
                <span v-for="(v, i) in productCode" :key="i">
                  <span v-if="i > 0">/</span>
                  {{ productList[v] }}
                </span>
              </p>
            </td>
            <td>
              <p>
                {{
                  configTab.useScene == "ONLINE"
                    ? "线上"
                    : configTab.useScene == "OFFLINE"
                    ? "线下"
                    : ""
                }}
              </p>
            </td>
            <td style="width: 66px">
              <p>
                <a
                  v-auth="'ACCOUNT:STORE:STORES:BASE/CONFIG'"
                  href="javascript:;"
                  @click="openPay"
                  class="lianjie"
                  >配置</a
                >
              </p>
            </td>
          </tr>
        </table>
      </div>

      <div class="desc" v-if="infoType != 'MANUAL'">
        <h5 class="jsxx">受益人信息</h5>
      </div>
      <div class="tab1" v-if="infoType != 'MANUAL'">
        <table class="tab">
          <tr class="tab_title">
            <td style="width: 100px">
              <p>受益人名称</p>
            </td>
            <td style="width: 100px">
              <p>受益人证件类型</p>
            </td>
            <td style="width: 100px">
              <p>受益人证件号</p>
            </td>
            <td style="width: 100px">
              <p>证件有效期</p>
            </td>
            <td style="width: 100px">
              <p>受益人证件正面照片</p>
            </td>
            <td style="width: 100px">
              <p>受益人证件反面照片</p>
            </td>
            <td style="width: 100px">
              <p>受益人居住地</p>
            </td>
            <!-- <td style="width: 100px">
              <p>操作</p>
            </td> -->
          </tr>
          <tr v-for="(v, i) in beneInfosData" :key="i">
            <td style="width: 100px">
              <p>{{ v.beneName }}</p>
            </td>
            <td style="width: 100px">
                <p v-show="v.beneType == '01'">身份证</p>
                <p v-show="v.beneType == '04'">外国护照</p>
                <p v-show="v.beneType == '09'">中国香港居民-来往内地通行证</p>
                <p v-show="v.beneType == '10'">中国澳门居民-来往内地通行证</p>
                <p v-show="v.beneType == '11'">中国台湾居民-来往大陆通行证</p>
            </td>
            <td style="width: 100px">
              <p>{{ v.beneIdNum }}</p>
            </td>
            <td style="width: 100px">
              <p>{{ v.beneValidDateStart }}至{{ v.beneValidDateEnd == "forever" ? "长期"  : v.beneValidDateEnd }}</p>
            </td>
            <td style="width: 100px">
              <p v-if="v.beneIdNomalPic != ''">
                  <a 
                    href="javacript:;"  style="color: #008aff;"
                    @click="showImg(v.beneIdNomalPic)"
                    >点击预览</a
                  >
              </p>
            </td>
            <td style="width: 100px">
              <p v-if="v.beneIdReversePic != ''">
                  <a
                    href="javacript:;" style="color: #008aff;"
                    @click="showImg(v.beneIdReversePic)"
                    >点击预览</a
                  >
              </p>
            </td>
            <td style="width: 100px">
              <p>{{ v.beneAddress }}</p>
            </td>
            <!-- <td style="width: 100px">
              <p>
                <i class="lianjie" @click="editTab(v, i)">编辑</i>
              </p>
            </td> -->
          </tr>
        </table>
      </div>
      <h6 class="xzjsxx" v-auth="'ACCOUNT:STORE:STORES:BASE/ADD'" @click="addBeneficiary" v-if="infoType != 'MANUAL'">
        <span>+</span> 新增受益人信息
      </h6>

      <div class="desc" v-if="infoType != 'MANUAL'">
        <h5 class="jsxx">进件信息</h5>
      </div>
      <div class="status_desc" v-if="infoType != 'MANUAL'">
        <span>进件状态</span>
        <span class="status">{{
          detail.applyStatus ? "已进件" : "未进件"
        }}</span>
        <el-button
          v-auth="'ACCOUNT:STORE:STORES:BASE/APPLY'"
          class="overBt active"
          @click="inGoods"
          type="primary"
          >进件</el-button
        >
        <!-- <el-button class="returnBt" type="primary">驳回</el-button> -->
      </div>
    </div>
    <div v-auth="'ACCOUNT:STORE:STORES:TRADE'" v-if="infoType == 'MANUAL'">
      <div class="desc">
        <h5 class="jsxx">报备商户号配置</h5>
      </div>
      <div class="tab1" style="margin-bottom: 18px">
        <table class="tab">
          <tr class="tab_title">
            <td>
              <p>报备商户号</p>
            </td>
            <td>
              <p>报备商户</p>
            </td>
            <td>
              <p>第三方商户号</p>
            </td>
            <td>
              <p>激活状态</p>
            </td>
            <td>
              <p>关联渠道</p>
            </td>
            <td>
              <p>备注</p>
            </td>
            <td style="width: 95px">
              <p>操作</p>
            </td>
          </tr>
          <tr v-for="(v, i) in userTabData" :key="i">
            <td>
              <p>{{ v.tradeMchNo }}</p>
            </td>
            <td>
              <p>{{ v.tradeMchName }}</p>
            </td>
            <td>
              <p>{{ v.thirdMchNo ? v.thirdMchNo : "" }}</p>
            </td>
            <td>
              <p v-show="OFFON_ActivateStatus != 'ACCOUNT:STORE:STORES:TRADE/ENABLED'"> {{ v.activateStatus==true ? "已开启" : "已关闭" }}</p>
              <p v-auth="'ACCOUNT:STORE:STORES:TRADE/ENABLED'">
                <el-switch
                  v-model="v.activateStatus"
                  @change="setUserTabStatus(v)"
                  active-color="#13ce66"
                >
                </el-switch>
              </p>
            </td>
            <td>
              <p>{{ v.mchNo }}</p>
            </td>
            <td>
              <p>{{ v.remark }}</p>
            </td>
            <td style="width: 95px">
              <p>
                <i 
                  v-auth="'ACCOUNT:STORE:STORES:TRADE/EDIT'"
                  class="lianjie"
                  @click="editUserTab(v)"
                  >编辑</i
                >
                <i class="lianjie" @click="delUserTab(v.tradeMchNo)"  v-auth="'ACCOUNT:STORE:STORES:TRADE/DELETE'">删除</i>
              </p>
            </td>
          </tr>
        </table>
      </div>
      <h6 class="xzjsxx" @click="addUserTabShow" v-auth="'ACCOUNT:STORE:STORES:TRADE/ADD'">
        <span>+</span> 新增报备商户号配置
      </h6>
    </div>
    <el-dialog title="修改结算信息" :visible.sync="editInfoShow" width="490px">
      <ul class="overAccount_ul">
        <li class="ul_left">
          <div class="overAccount_ul_title"><i>*</i>账户类型</div>
          <el-select
            v-model="accountFormData.accountType"
            placeholder="选择账户类型"
          >
            <el-option label="企业" value="COMPANY"></el-option>
            <el-option label="个人" value="PERSONAL"></el-option>
          </el-select>
          <div class="overAccount_ul_title"><i>*</i>账户</div>
          <el-input
            v-model="accountFormData.accountNo"
            placeholder="输入账户"
          ></el-input>
        </li>
        <li class="ul-right">
          <div class="overAccount_ul_title"><i>*</i>账户名</div>
          <el-input
            v-model="accountFormData.accountName"
            placeholder="输入账户名"
          ></el-input>
          <div class="overAccount_ul_title">
            <i v-if="accountFormData.accountType != 'PERSONAL'">*</i>联行号
          </div>
          <el-input
            v-model="accountFormData.interBranchesNo"
            placeholder="输入联行号"
          ></el-input>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="editInfoShow = false"
          >取 消</el-button
        >
        <el-button class="addBt" @click="editAccount">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="配置信息" :visible.sync="editPayShow" width="490px">
      <ul class="overAccount_ul">
        <li style="width: 400px; margin: 0 auto">
          <div class="overAccount_ul_title">支付类型</div>
          <el-checkbox v-model="payFormData" label="wxpay"
            >微信支付</el-checkbox
          >
          <el-checkbox v-model="payFormData" label="alipay"
            >支付宝支付</el-checkbox
          >
          <el-checkbox v-model="payFormData" label="unionpay"
            >银联支付</el-checkbox
          >
          <div class="overAccount_ul_title">使用场景</div>
          <el-radio v-model="configTab1.useScene" label="OFFLINE"
            >线下</el-radio
          >
          <el-radio v-model="configTab1.useScene" label="ONLINE">线上</el-radio>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="editPayShow = false">取 消</el-button>
        <el-button class="addBt" @click="editConfig">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="editUserIndex ? '修改报备商户号配置' : '新增报备商户号配置'"
      :visible.sync="overAccountShow"
      width="490px"
    >
      <ul class="overAccount_ul">
        <li class="ul_left">
          <div class="overAccount_ul_title"><i>*</i>报备商户号</div>
          <el-input
            v-model="userFormData.tradeMchNo"
            :disabled="editUserIndex"
            placeholder="输入报备商户号"
          ></el-input>
          <div class="overAccount_ul_title">第三方商户号</div>
          <el-input
            v-model="userFormData.thirdMchNo"
            placeholder="输入第三方商户号"
          ></el-input>
          <div class="overAccount_ul_title">备注</div>
          <el-input
            v-model="userFormData.remark"
            maxlength="128"
            placeholder="输入备注"
            style="width: 440px"
          ></el-input>
        </li>
        <li class="ul-right">
          <div class="overAccount_ul_title"><i>*</i>报备商户</div>
          <el-input
            v-model="userFormData.tradeMchName"
            placeholder="输入报备商户"
          ></el-input>
          <div class="overAccount_ul_title"><i>*</i>关联渠道</div>
          <el-select
            class="inputs"
            v-model="userFormData.mchChannelId"
            placeholder="选择关联渠道"
          >
            <el-option
              v-for="v in mchchannelsDrop"
              :key="v.mchChannelId"
              :label="v.mchNo"
              :value="v.mchChannelId"
            >
            </el-option>
          </el-select>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="overAccountShow = false"
          >取 消</el-button
        >
        <el-button class="addBt" @click="addUserTab" :disabled="buttonFlag">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="修改店铺信息"
      :visible.sync="modifyManualShow"
      width="490px"
    >
      <ul class="overAccount_ul">
        <li class="ul_left">
          <div class="overAccount_ul_title"><i>*</i>店铺名称</div>
          <el-input
            v-model="modifyManualForm.storeName"
            :disabled="editUserIndex"
            placeholder="输入店铺名称"
          ></el-input>
          <div class="overAccount_ul_title">商户显示状态</div>
          <el-switch
            v-model="modifyManualForm.showInMch"
            active-color="#13ce66"
          ></el-switch>
        </li>
        <li class="ul-right">
          <div class="overAccount_ul_title"><i>*</i>所属商户</div>
          <el-select
            class="inputs"
            v-model="modifyManualForm.mchName"
            placeholder="选择所属商户"
            disabled
          >
            <el-option
              v-for="v in mchchannelsDrop"
              :key="v.mchChannelId"
              :label="v.mchChannelName"
              :value="v.mchChannelId"
            >
            </el-option>
          </el-select>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="modifyManualShow = false"
          >取 消</el-button
        >
        <el-button class="addBt" @click="editManual">确 定</el-button>
      </span>
    </el-dialog>
    <div class="cover_view" v-if="showImgUrl !== ''" @click="showImgUrl = ''">
      <a :href="showImgUrl" target="_blank">
        <img :src="showImgUrl" alt="加载失败" />
      </a>
    </div>

    <!-- 新增受益人 -->
    <el-dialog title="受益人信息" :visible.sync="overAccountBeneficiaryShow" width="600px">
      <ul class="overAccount_ul" style="display:flex;justify-content: space-around;">
        <li class="ul_left">
          <div class="overAccount_ul_title"><i>*</i>受益人名称</div>
          <el-input
            v-model="formData.beneName"
            maxlength="128"
            placeholder="输入受益人名称"
            tabindex="2"
          ></el-input>
          <div class="overAccount_ul_title"><i>*</i>受益人证件号</div>
          <el-input
            v-model="formData.beneIdNum"
            maxlength="128"
            placeholder="输入受益人证件号"
            tabindex="3"
          ></el-input>
          <div class="overAccount_ul_title"><i>*</i>受益人居住地</div>
          <el-input
            v-model="formData.beneAddress"
            maxlength="128"
            placeholder="输入受益人居住地"
            tabindex="3"
          ></el-input>
          <div class="uploader1">
            <div class="overAccount_ul_title"><i>*</i>受益人证件正面照片</div>
            <el-upload
              class="avatar-uploader"
              :before-upload="beforeUpload"
              drag
              :action="uploadUrl"
              :show-file-list="false"
              :on-success="contactIdNomalPicSuccess"
            >
              <img
                v-if="formData.beneIdNomalPic"
                :src="formData.beneIdNomalPic"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              <span v-if="formData.beneIdNomalPic" class="avatar-uploader-span" @click.stop="formData.beneIdNomalPic = ''"><i class="el-icon-close"></i></span>
            </el-upload>
          </div>
        </li>
        <li class="ul-right">
          <div class="overAccount_ul_title"><i>*</i>受益人证件类型</div>
          <el-select
            v-model="formData.beneType"
            placeholder="选择受益人证件类型"
            tabindex="1"
          >
            <el-option
              v-for="(v, i) in legalPersonCemType"
              :key="i"
              :label="v"
              :value="i"
            >
            </el-option>
          </el-select>
          <div class="overAccount_ul_title"><i>*</i>证件有效期</div>
          <el-date-picker
            class="inputs pick"
            v-model="formData.beneValidDateStart"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="开始日期"
          >
          </el-date-picker>
          <span style="padding-left: 10px; padding-right: 10px;height: 36px;line-height: 36px;">至</span>
          <el-date-picker
            class="inputs pick"
            v-model="formData.beneValidDateEnd"
            :picker-options="beneValidDateEndFV"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="结束日期"
          >
          </el-date-picker>
          <div class="uploader1">
            <div class="overAccount_ul_title"><i>*</i>受益人证件反面照片</div>
            <el-upload
              class="avatar-uploader"
              :before-upload="beforeUpload"
              drag
              :action="uploadUrl"
              :show-file-list="false"
              :on-success="contactIdReversePicSuccess"
            >
              <img
                v-if="formData.beneIdReversePic"
                :src="formData.beneIdReversePic"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              <span v-if="formData.beneIdReversePic" class="avatar-uploader-span" @click.stop="formData.beneIdReversePic = ''"><i class="el-icon-close"></i></span>
            </el-upload>
          </div>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="overAccountBeneficiaryShow = false"
          >取 消</el-button
        >
        <el-button class="addBt" :disabled="buttonFlag" @click="sureAddBeneficiary"
          >确 定</el-button
        >
      </span>
    </el-dialog>

  </div>
</template>
<script>
import {
  getManualDetail,
  setTrade,
  delTrade,
  getApi,
  setAccount,
  setConfig,
  setApply,
  setManual,
  setTradeActivate,
  newBeneficiary,
} from "@/api/user/shopList.js";
import { mchchannelsDrop } from "@/api/user/shopManager.js";
import address from "@/utils/address.json";
import category from "@/utils/category.json";
import { URL_API } from "@/utils/apiUrl.js";
import * as imageConversion from "image-conversion";
var beneValidDateEnd = null ;
export default {
  data() {
    return {
      editInfoShow: false, //控制修改结算信息弹窗
      accountFormData: {}, //修改结算信息弹框数据
      editPayShow: false, //控制修改配置弹窗
      payFormData: [], //修改配置信息的弹窗数据
      overAccountShow: false, //修改报备商户号配置
      infoType: "",
      detail: {}, //详情
      userTabData: [],
      userFormData: {
        tradeMchNo: "",
        tradeMchName: "",
        thirdMchNo: "",
        mchChannelId: "",
        remark: "",
      }, //手工新增报备商户号配置表单
      editUserIndex: false,
      showImgUrl: "",
      address,
      category,
      category2: "",
      category3: "",
      merchantType: {
        ENTERPRISE: "企业",
        INDIVIDUAL_BUSINESS: "个体工商户",
        MICRO_ENTERPRISE: "小微商户",
        PUBLIC_INSTITUTION: "事业单位",
        PRIVATE_NONENTERPRISE_UNIT: "民办非企业单位",
        SOCIAL_ORGANIZATION: "社会团体",
      },
      legalPersonCemType: {
        "01": "身份证",
        "04": "外国护照",
        "09": "中国香港居民-来往内地通行证",
        10: "中国澳门居民-来往内地通行证",
        11: "中国台湾居民-来往大陆通行证",
      },
      licenceType: [
        "统一社会信用代码证书",
        "有偿服务许可证（军队医院适用）",
        "医疗机构执业许可证（军队医院适用）",
        "企业营业执照（挂靠企业的党组织适用）",
        "组织机构代码证（政府机关适用）",
        "社会团体法人登记证书",
        "民办非企业单位登记证书",
        "基金会法人登记证书",
        "慈善组织公开募捐资格证书",
        "农民专业合作社法人营业执照",
        "宗教活动场所登记证",
        "营业执照",
        "营业执照号码（多合一）",
        "事业单位法人证书",
        "其他证书/批文/证明",
      ],
      microType: {
        MICRO_TYPE_STORE: "店铺场所",
        MICRO_TYPE_MOBILE: "流动经营/便民服务",
        MICRO_TYPE_ONLINE: "线上商品/服务交易",
      },
      accountTab: {},
      accountTab1: {},
      configTab: {},
      configTab1: {},
      productCode: [],
      productCode1: [],
      productList: {
        wxpay: "微信支付",
        alipay: "支付宝支付",
        unionpay: "银联支付",
      },
      mchchannelsDrop: [],
      modifyManualForm: {}, //修改店铺
      modifyManualShow: false,
      beneInfosData:{},

      formData: { 
        beneName: "", //受益人名称
        beneIdNum: "", //受益人身份证号	
        beneAddress:"", //受益人地址
        beneType: "", //受益人证件类型
        beneIdNomalPic:"", //受益人证件正面照片
        beneIdReversePic:"",//受益人证件反面照片
        beneValidDateStart: "", //true	证件开始日期，格式yyyy-MM-dd
        beneValidDateEnd: "", //true 证件结束日期，格式yyyy-MM-dd，可选特殊值forever表示长期
      },
      beneValidDateEndFV: {
        shortcuts: [
          {
            text: "长期",
            onClick(picker) {
              picker.$emit("pick", "2999-01-01");
              beneValidDateEnd = "forever";
            },
          },
        ],
      },
      tabData: [],
      uploadUrl: URL_API.upload,
      buttonFlag: false, //防止重复点击
      editIndex: "",
      overAccountBeneficiaryShow:false,
      OFFON_ActivateStatus:'ACCOUNT:STORE:STORES:TRADE/ENABLED',
    };
  },
  created() {
    this.infoType = this.$route.query.InfoType;
    this.getDetail();
    this.listJurisdiction();
  },
  methods: {

    // 新增受益人
    addBeneficiary() {
      if(this.beneInfosData.length >= 4){
          this.$message.success("最多只能新增4条受益人信息");
          return false;
      }
      this.formData = {
        beneName: "", //受益人名称
        beneIdNum: "", //受益人身份证号	
        beneAddress:"", //受益人地址
        beneType: "", //受益人证件类型
        beneIdNomalPic:"", //受益人证件正面照片
        beneIdReversePic:"",//受益人证件反面照片
        beneValidDateStart: "", //true	证件开始日期，格式yyyy-MM-dd
        beneValidDateEnd: "", //true 证件结束日期，格式yyyy-MM-dd，可选特殊值forever表示长期
      };
      this.editIndex = null;
      this.overAccountBeneficiaryShow = true;
    },
    // 确认添加-新增受益人
    sureAddBeneficiary() {
      let data = JSON.parse(JSON.stringify(this.formData));
      if (!data.beneName) {
        this.$message.error("受益人名称必填");
        return false;
      }
      if (!data.beneIdNum) {
        this.$message.error("证件号必填");
        return false;
      }
       if (!data.beneType) {
          this.$message.error("受益人证件类型必选");
          return false;
      }
      if (!data.beneAddress) {
        this.$message.error("受益人地址必填");
        return false;
      }
      if (!data.beneValidDateStart) {
          this.$message.error("证件开始日期必选");
          return false;
      }
      if (!data.beneValidDateEnd && beneValidDateEnd != "2999-01-01") {
          this.$message.error("证件结束日期必选");
          return false;
      }
      if (!data.beneIdNomalPic) {
          this.$message.error("受益人身份证正面照片必传");
          return false;
      }
      if (!data.beneIdReversePic) {
          this.$message.error("受益人身份证反面照片必传");
          return false;
      }
      if(data.beneValidDateEnd == '2999-01-01' && beneValidDateEnd == "forever"){
        data.beneValidDateEnd = "forever";
      }
      data.storeId = this.detail.storeId; 
      this.buttonFlag = true;     
      // this.beneInfosData.push(data);
      console.log(data);
      newBeneficiary(data).then((res) => {
          if (res.resultStatus) {
            this.beneInfosData={};
            this.getDetail();
            this.$message.success("保存成功");
            this.overAccountBeneficiaryShow = false;
            this.buttonFlag = false;
          }
      });

    },
    // 身份证正面照片上传
    contactIdNomalPicSuccess(res) {
      if (res.resultStatus) {
        this.formData.beneIdNomalPic = res.resultData.fileLink;
      }
    },
     // 身份证反面照片上传
    contactIdReversePicSuccess(res) {
      if (res.resultStatus) {
        this.formData.beneIdReversePic = res.resultData.fileLink;
      }
    },

    getDetail() {
      console.log("详情infoTypeinfoTypeinfoType",this.infoType)
      if (this.infoType == "MANUAL") {
        getManualDetail(this.$route.query.id).then((res) => {
          if (res.resultStatus) {
            this.detail = res.resultData.storeDetail;
            this.userTabData = res.resultData.storeTrades;
            mchchannelsDrop(this.detail.mchId).then((res) => {
              if (res) {
                this.mchchannelsDrop = res.resultData;
              }
            });
          }
        });
      } else {
        getApi(this.$route.query.id).then((res) => {
          console.log("res",res)
          if (res.resultStatus) {
            this.detail = res.resultData.storeDetail;
            this.detail.licenceType = Number(this.detail.licenceType) - 1;
            this.accountTab = res.resultData.account;
            this.accountTab1 = JSON.parse(
              JSON.stringify(res.resultData.account)
            );
            this.configTab = res.resultData.config;
            this.configTab1 = JSON.parse(JSON.stringify(res.resultData.config));
            this.productCode = this.configTab.productCode.split(",");
            this.beneInfosData =  res.resultData.beneInfos;
            this.getAddress();
            this.getCategory();
          }
        });
      }
    },
    //新增展示
    addUserTabShow() {
      this.userFormData = {
        tradeMchNo: "",
        tradeMchName: "",
        thirdMchNo: "",
        mchChannelId: "",
        remark: "",
      };
      this.editUserIndex = false;
      this.overAccountShow = true;
    },
    //新增
    addUserTab() {
      let data = JSON.parse(JSON.stringify(this.userFormData));
      if (!data.tradeMchNo) {
        this.$message.error("报备商户号必填");
        return;
      }
      if (!data.tradeMchName) {
        this.$message.error("报备商户名称必填");
        return;
      }
      if (!data.mchChannelId) {
        this.$message.error("关联渠道必选");
        return;
      }
      let obj = {
        storeTrades: [data],
      };
      this.buttonFlag = true;
      setTrade(this.$route.query.id, obj).then((res) => {
        if (res.resultStatus) {
          this.$message.success("保存成功");
          this.getDetail();
          this.overAccountShow = false;
        }
        setTimeout(() => {
          this.buttonFlag = false;
        }, 2000);
      });
    },
    //修改
    editUserTab(formData) {
      this.userFormData = JSON.parse(JSON.stringify(formData));
      this.overAccountShow = true;
      this.editUserIndex = true;
    },
    //删除
    delUserTab(id) {
      this.$confirm("此操作将删除该配置, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delTrade(this.$route.query.id, id).then((res) => {
            if (res.resultStatus) {
              this.getDetail();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 页面修改状态的跳转
    toTypeUrl(name) {
      this.$router.push({
        name: name,
        query: {
          pageType: "edit",
          id: this.$route.query.id,
          InfoType: this.$route.query.InfoType,
        },
      });
    },
    // 浏览单个图片
    showImg(val) {
      this.showImgUrl = val;
    },
    //获取地址
    getAddress() {
      let province = this.address.find(
        (v) => v.provinceCode == this.detail.province
      ).citys;
      this.detail.province = this.address.find(
        (v) => v.provinceCode == this.detail.province
      ).province;
      let city = province.find((v) => v.cityCode == this.detail.city).countys;
      this.detail.city = province.find(
        (v) => v.cityCode == this.detail.city
      ).city;
      this.detail.county = city.find(
        (v) => v.countyCode == this.detail.county
      ).county;
    },
    //获取经营类目
    getCategory() {
      for (let i in this.category) {
        for (let ind in this.category[i][0]) {
          for (let index in this.category[i][0][ind]) {
            if (
              this.category[i][0][ind][index]["编号"] == this.detail.category
            ) {
              this.category2 = i;
              this.category3 = ind;
              this.detail.category =
                this.category[i][0][ind][index]["二级类目"];
            }
          }
        }
      }
    },
    // 点击编辑结算信息
    openEditInfo() {
      this.accountFormData = JSON.parse(JSON.stringify(this.accountTab1));
      this.editInfoShow = true;
    },
    rules() {
      let data = JSON.parse(JSON.stringify(this.accountFormData));
      if (!data.accountName) {
        this.$message.error("账户名必填");
        return false;
      }

      if (!data.accountNo) {
        this.$message.error("账户必填");
        return false;
      }

      if (data.accountType == "COMPANY" && !data.interBranchesNo) {
        this.$message.error("联行号必填");
        return false;
      }
      return true;
    },
    //修改结算信息确认
    editAccount() {
      // console.log("aaaaaaa", this.accountFormData);
      // return;
      if (this.rules()) {
        setAccount(this.$route.query.id, this.accountFormData).then((res) => {
          if (res.resultStatus) {
            this.$message.success("保存成功");
            this.getDetail();
            this.editInfoShow = false;
          }
        });
      }
    },
    //点击修改配置
    openPay() {
      this.payFormData = JSON.parse(JSON.stringify(this.productCode));
      this.editPayShow = true;
    },
    //修改配置信息
    editConfig() {
      let tempArr = this.payFormData.filter((e) => {
        return e != "";
      });
      if (tempArr.length > 0) {
        let arry = [];
        this.payFormData.forEach((e) => {
          // console.log(e)
          if (e) {
           arry.push(e)
          }
        });
        this.configTab1.productCode =arry.join(",");

        // this.configTab1.productCode = this.payFormData.join(",");
        console.log( this.configTab1.productCode);

        // console.log(this.payFormData);

        console.log(this.configTab1);
        // return;
        setConfig(this.$route.query.id, this.configTab1).then((res) => {
          if (res.resultStatus) {
            this.$message.success("保存成功");
            this.getDetail();
            this.editPayShow = false;
          }
        });
      } else {
        this.$message.error("支付类型必选");
      }
    },
    // 进件
    inGoods() {
      this.$confirm("是否进行进件", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          setApply(this.$route.query.id).then((res) => {
            if (res.resultStatus) {
              this.getDetail();
              this.$message.success("进件成功");
            }
          });
        })
        .catch(() => {
          console.log("取消");
        });
    },
    // 修改门店信息
    modifyManual() {
      this.modifyManualForm = JSON.parse(JSON.stringify(this.detail));
      this.modifyManualShow = true;
    },
    // 确认修改门店信息
    editManual() {
      let data = { ...this.modifyManualForm };
      if (!data.storeName) {
        this.$message.error("门店名称必填");
        return;
      }
      setManual(this.$route.query.id, data).then((res) => {
        if (res.resultStatus) {
          this.$message.success("保存成功");
          this.getDetail();

          this.modifyManualShow = false;
        }
      });
    },
    // 修改报备商户号激活状态
    setUserTabStatus(value) {
      setTradeActivate(this.$route.query.id, value).then((res) => {
        if (!res.resultStatus) {
          value.activateStatus = value.activateStatus ? false : true;
        }
      });
    },
    beforeUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      let extension =
        testmsg === "jpg" ||
        testmsg === "JPG" ||
        testmsg === "jpeg" ||
        testmsg === "JPEG" ||
        testmsg === "png" ||
        testmsg === "PNG" ;
      if (!extension) {
        this.$message.error("上传图片仅支持jpg/jpeg/png格式");
      }
      if (!extension) {
        return false;
      }
      return new Promise((resolve) => {
        let isLt2M = file.size / 1024 < 2048; // 判定图片大小是否小于2MB = 2048kb
        if (isLt2M) {
          resolve(file);
        }
        // 压缩到2048KB,这里的2048就是要压缩的大小,可自定义
        imageConversion.compressAccurately(file, 2048).then((res) => {
          resolve(res);
        });
      });
    }, 
    //判断列表按钮是否有权限
    listJurisdiction(){
        const _resources = JSON.parse(sessionStorage.getItem('userInfo')).resources;
        if(_resources=='ALL'){
            this.OFFON_ActivateStatus = 'ACCOUNT:STORE:STORES:TRADE/ENABLED';
        }else{
          if(_resources.split(';').indexOf('ACCOUNT:STORE:STORES:TRADE/ENABLED') !== -1 ){
            this.OFFON_ActivateStatus = 'ACCOUNT:STORE:STORES:TRADE/ENABLED';
          }else{
            this.OFFON_ActivateStatus = "";
          }
        }

    },

  },
     
 
};
</script>
<style scoped>
@import "../../../../assets/css/desc.css";
@import "../../../../assets/css/detail.css";
.tab1{
  min-height: auto !important;
}


.title {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 40px;
}


.status_desc {
  height: 32px;
  margin: 20px 0;
  padding: 10px 30px 10px 35px;
  background-color: #f4f5f6;
  font-size: 16px;
  line-height: 32px;
  color: #999999;
}

.status_desc .status {
  margin-left: 56px;
  color: #333333;
}

.status_desc .overBt {
  float: right;
  width: 60px;
  height: 32px;
  line-height: 32px;
  margin-left: 10px;
  padding: 0;
  background-color: #aaaaaa;
  border-color: #aaaaaa;
  font-size: 12px;
  color: #ffffff;
  border-radius: 3px;
}

.status_desc .overBt.active {
  background: rgba(72, 184, 182, 1);
  border-color: #5aafab;
}

.status_desc .returnBt {
  float: right;
  width: 60px;
  height: 32px;
  line-height: 32px;
  padding: 0;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(215, 218, 226, 1);
  border-radius: 3px;
  font-size: 12px;
  color: #333333;
}
.overAccount_ul {
  overflow: hidden;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: #999999;
  text-align: left;
}
.overAccount_ul_title i {
  color: #48b8b6;
  padding-right: 3px;
}

.overAccount_ul .el-input,
.overAccount_ul .el-select {
  margin-bottom: 7px;
}

.overAccount_ul .ul_left {
  float: left;
  width: 200px;
  margin-left: 24px;
}

.overAccount_ul .ul-right {
  float: right;
  width: 200px;
  margin-right: 26px;
}

.addBt {
  width: 60px;
  height: 32px;
  background-color: #48b8b6;
  border-color: #5aafab;
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  border-color: #dce0e6;
  color: #333333;
  font-size: 12px;
  padding: 0;
}

.avatar-uploader {
  width: 120px;
  height: 120px;
  background: rgba(246, 246, 246, 1);
  border: 1px solid rgba(215, 218, 226, 1);
  border-radius: 4px;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}

.overAccount_ul_title i{
    color: #48b8b6;
    padding-right: 3px;
}

.avatar {
  width: 120px;
  height: 120px;
  display: block;
}
.el-date-editor.el-input, .el-date-editor.el-input__inner{width: 200px;}
</style>
